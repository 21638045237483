<template>
  <div class="contract" v-if="dialogVisible">
    <div class="contract-main">
      <section>
        <img class="title" src="@/assets/map/detail-title.png" alt="" />
        <i class="el-icon-circle-close icon" @click="dialogVisible = false"></i>
      </section>
      <section class="contract-main-title">
        <div class="line-left"></div>
        <span>合同信息</span>
        <div class="line-right"></div>
      </section>
      <section class="contract-main-form" v-if="!imgShow">
        <ul>
          <li>
            <div class="container">
              <span class="form-title">项目名称</span>
              <div class="content">{{ detailData.projName }}</div>
            </div>
            <div class="container">
              <span class="form-title">项目所在地</span>
              <div class="content">{{ detailData.projAddress }}</div>
            </div>
          </li>
          <li>
            <div class="container">
              <span class="form-title">开发商</span>
              <div class="content">{{ detailData.developer }}</div>
            </div>
            <div class="container">
              <span class="form-title">承建商</span>
              <div class="content">{{ detailData.constractor }}</div>
            </div>
          </li>
          <li>
            <div class="container">
              <span class="form-title">合同签约单位</span>
              <div class="content">{{ detailData.contractEntp }}</div>
            </div>
            <div class="container">
              <span class="form-title">合同编号</span>
              <div class="content">{{ detailData.contractNo }}</div>
            </div>
          </li>
          <li>
            <div class="container">
              <span class="form-title">销售模式</span>
              <div class="content">{{ detailData.sellMode == 'SELL' ? '销售' : '出租' }}</div>
            </div>
            <div class="container">
              <span class="form-title">铝膜栋数</span>
              <div class="content">{{ detailData.lvDs }}</div>
            </div>
          </li>
          <li>
            <div class="container">
              <span class="form-title">单层面积</span>
              <div class="content">{{ detailData.areaEc }}</div>
            </div>
            <div class="container">
              <span class="form-title">层数</span>
              <div class="content">{{ detailData.lvCs }}</div>
            </div>
          </li>
          <li>
            <div class="container">
              <span class="form-title">项目类别</span>
              <div class="content">{{ detailData.projType | getType }}</div>
            </div>
            <div class="container">
              <span class="form-title">业务负责人</span>
              <div class="content">{{ detailData.projLeader }}</div>
            </div>
          </li>
          <li>
            <div class="container">
              <span class="form-title">铝模板综合单价</span>
              <div class="content">{{ detailData.lvPrices }}</div>
            </div>
            <div class="container">
              <span class="form-title">合同金额</span>
              <div class="content">{{ detailData.contractAmt }}</div>
            </div>
          </li>
          <li>
            <div class="container">
              <span class="form-title">租期</span>
              <div class="content">{{ detailData.rentTerm }}</div>
            </div>
            <div class="container">
              <span class="form-title">合同进场时间</span>
              <div class="content">{{ detailData.inDate }}</div>
            </div>
          </li>
          <li>
            <div class="container">
              <span class="form-title">合同</span>
              <div style="display: flex" v-if="detailData.imgContract">
                <div class="content-bg" v-loading="loading" v-for="(item, index) in imgContract" :key="index" v-show="index < 3">
                  <img :src="'data:image/jpeg;base64,' + item" preview="contract" class="preview-img" />
                </div>
              </div>
              <p style="color: #fff" v-else>暂无图片</p>
            </div>
            <div class="container">
              <span class="form-title">发票</span>
              <div style="display: flex" v-if="detailData.imgContract">
                <div class="content-bg" v-loading="loading" v-for="(item, index) in imgBill" :key="index" v-show="index < 3">
                  <img :src="'data:image/jpeg;base64,' + item" preview="bill" class="preview-img" />
                </div>
              </div>
              <p style="color: #fff" v-else>暂无图片</p>
            </div>
          </li>
          <li>
            <div class="container">
              <span class="form-title">物流单</span>
              <div style="display: flex" v-if="detailData.imgContract">
                <div class="content-bg" v-loading="loading" v-for="(item, index) in imgLogistics" :key="index" v-show="index < 3">
                  <img :src="'data:image/jpeg;base64,' + item" preview="logistics" class="preview-img" />
                </div>
              </div>
              <p style="color: #fff" v-else>暂无图片</p>
            </div>
            <div class="container">
              <span class="form-title">银行回单</span>
              <div style="display: flex" v-if="detailData.imgContract">
                <div class="content-bg" v-loading="loading" v-for="(item, index) in imgReceipt" :key="index" v-show="index < 3">
                  <img :src="'data:image/jpeg;base64,' + item" preview="receipt" class="preview-img" />
                </div>
              </div>
              <p style="color: #fff" v-else>暂无图片</p>
            </div>
          </li>
          <li>
            <div class="container">
              <span class="form-title">操作</span>
              <div class="content mouseStyle" @click="showMore">点击查看设备信息</div>
            </div>
            <div class="container">
              <span class="form-title">定位</span>
              <div class="content mouseStyle" @click="toPosition(detailData)">点击跳转到项目位置</div>
            </div>
          </li>
          <li>
            <div class="container">
              <span class="form-title">铝模板溯源模</span>
              <div class="content mouseStyle" @click="showTraceability">点击查看铝模板溯源模</div>
            </div>
            <div class="container">
              <span class="form-title">物资交付</span>
              <div class="content mouseStyle" @click="showDeliveries">点击查看物资交付信息</div>
            </div>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>
<script>
import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css';
import Vue from 'vue';
import {selectContractById, getRelateImgById} from '../api';
var options = {
  fullscreenEl: false //关闭全屏按钮
};
Vue.use(preview, options);
export default {
  data() {
    return {
      imgContract: [], //合同
      imgBill: [], //发票
      imgLogistics: [], //物流单
      imgReceipt: [], //回单
      detailData: {},
      id: '', // 通过父组件直接赋值
      imgShow: false,
      dialogVisible: false,
      loading: true,
    };
  },
  filters: {
    getType(val) {
      switch (val) {
        case 'ZZ':
          return '住宅';
        case 'SY':
          return '商用';
        case 'XZL':
          return '写字楼';
        case 'ORTHER':
          return '其他';
      }
    }
  },
  watch: {
    dialogVisible: function (val) {
      if (val) {
        this.loading = true;
        // 获取详情
        this.getdetail();
      }
    }
  },
  methods: {
    formatImg() {
      if (this.detailData.imgContract.split(',').length < 3) {
        this.imgContract = Array.from({length: this.detailData.imgContract.split(',').length}, (v, i) => i);
      } else {
        this.imgContract = Array.from({length: 3}, (v, i) => i);
      }
      if (this.detailData.imgBill.split(',').length < 3) {
        this.imgBill = Array.from({length: this.detailData.imgBill.split(',').length}, (v, i) => i);
      } else {
        this.imgBill = Array.from({length: 3}, (v, i) => i);
      }
      if (this.detailData.imgLogistics.split(',').length < 3) {
        this.imgLogistics = Array.from({length: this.detailData.imgLogistics.split(',').length}, (v, i) => i);
      } else {
        this.imgLogistics = Array.from({length: 3}, (v, i) => i);
      }
      if (this.detailData.imgReceipt.split(',').length < 3) {
        this.imgReceipt = Array.from({length: this.detailData.imgReceipt.split(',').length}, (v, i) => i);
      } else {
        this.imgReceipt = Array.from({length: 3}, (v, i) => i);
      }
    },
    toPosition(item) {
      fetch(`https://restapi.amap.com/v3/geocode/geo?address=${item.projAddress}&key=bd6fec6882f7ed8c715e5aab781e7eeb`, {
        method: 'GET'
      }).then(async res => {
        let myres = await res.json();
        if (myres.status == 1) {
          let myGeocodes = myres.geocodes[0].location.split(',');
          this.$store.commit('changeGeocodeData', myGeocodes);
          this.dialogVisible = false;
        } else {
          this.$message.error(myres.info);
        }
      });
    },
    async getRelateImgById() {
      let res = await getRelateImgById({id: this.detailData.id});
      if (res.errno == 0) {
        setTimeout(() => {
          // 调用图片预览方法
          this.$previewRefresh();
        }, 100);
        console.log(res);
        this.imgContract = res.data.imgContract;
        this.imgBill = res.data.imgBill;
        this.imgLogistics = res.data.imgLogistics;
        this.imgReceipt = res.data.imgReceipt;
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
    async getdetail() {
      let res = await selectContractById({id: this.id});
      if (res.errno === 0) {
        this.detailData = res.data;
        this.formatImg();
        // 获取各种图片
        this.getRelateImgById();
      }
    },
    showMore() {
      this.$emit('toEquipment', {id: this.id});
    },
    showTraceability() {
      this.$emit('toTraceabilityDetail', this.detailData);
    },

    showDeliveries() {
      this.$emit('toDeliveriesDetail', {id: this.id});
    }
  }
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.content-bg {
  width: 85px;
  height: 60px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #bbb;
}
.contract {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  background: rgba(1, 3, 9, 0.7);
  font-size: 15px;
  &-main {
    padding: 0 72px;
    position: absolute;
    top: calc(50% - 370px);
    left: calc(50% - 531px);
    background: url('../../../assets/map/detail.png') no-repeat center/100%;
    z-index: 98;
    width: 1062px;
    height: 740px;
    .title {
      display: block;
      width: 244px;
      height: 50px;
      margin: -18px auto 0;
    }
    .icon {
      font-size: 36px;
      color: #34defc;
      position: absolute;
      top: -18px;
      right: 0;
    }
    &-title {
      height: 20px;
      font-size: 18px;
      color: #06d5f5;
      margin: 23px 0;
      display: flex;
      align-items: center;
      .line-left,
      .line-right {
        height: 2px;
        width: 420px;
        background-image: linear-gradient(to right, rgba(6, 172, 212, 1), rgba(6, 172, 212, 0));
      }
      .line-right {
        background-image: linear-gradient(to left, rgba(6, 172, 212, 1), rgba(6, 172, 212, 0));
      }
    }
    .preview-img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    &-form {
      height: 536px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #49e3fb;
        border-radius: 5px;
      }

      li {
        display: flex;
        margin-bottom: 34px;
      }
      .container {
        width: 50%;
        display: flex;
        align-items: center;
      }
      .form-title {
        color: #06d5f5;
        width: 120px;
        text-align: right;
        margin-right: 18px;
      }
      .content {
        color: #fff;
        height: 32px;
        display: flex;
        align-items: center;
        width: 282px;
        padding: 0 15px;
        border-radius: 50px;
        background-color: #192b87;
        color: #fff;
      }
      .my-contract {
        width: 200px;
        margin-right: 0 !important;
      }
      .my-container {
        width: 282px;
        display: flex;
        justify-content: space-between;
      }
      .look {
        color: #fff;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        font-size: 14px;
        border-radius: 50px;
        background-color: #192b87;
      }
    }
  }
}
</style>
