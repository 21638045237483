var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c("div", { staticClass: "contract" }, [
        _c("div", { staticClass: "contract-main" }, [
          _c("section", [
            _c("img", {
              staticClass: "title",
              attrs: { src: require("@/assets/map/detail-title.png"), alt: "" },
            }),
            _c("i", {
              staticClass: "el-icon-circle-close icon",
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            }),
          ]),
          _vm._m(0),
          !_vm.imgShow
            ? _c("section", { staticClass: "contract-main-form" }, [
                _c("ul", [
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("项目名称"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.projName)),
                      ]),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("项目所在地"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.projAddress)),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("开发商"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.developer)),
                      ]),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("承建商"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.constractor)),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("合同签约单位"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.contractEntp)),
                      ]),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("合同编号"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.contractNo)),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("销售模式"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.sellMode == "SELL" ? "销售" : "出租"
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("铝膜栋数"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.lvDs)),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("单层面积"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.areaEc)),
                      ]),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("层数"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.lvCs)),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("项目类别"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(_vm._f("getType")(_vm.detailData.projType))
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("业务负责人"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.projLeader)),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("铝模板综合单价"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.lvPrices)),
                      ]),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("合同金额"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.contractAmt)),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("租期"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.rentTerm)),
                      ]),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("合同进场时间"),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.inDate)),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("合同"),
                      ]),
                      _vm.detailData.imgContract
                        ? _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(_vm.imgContract, function (item, index) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: index < 3,
                                      expression: "index < 3",
                                    },
                                  ],
                                  key: index,
                                  staticClass: "content-bg",
                                },
                                [
                                  _c("img", {
                                    staticClass: "preview-img",
                                    attrs: {
                                      src: "data:image/jpeg;base64," + item,
                                      preview: "contract",
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("p", { staticStyle: { color: "#fff" } }, [
                            _vm._v("暂无图片"),
                          ]),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("发票"),
                      ]),
                      _vm.detailData.imgContract
                        ? _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(_vm.imgBill, function (item, index) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: index < 3,
                                      expression: "index < 3",
                                    },
                                  ],
                                  key: index,
                                  staticClass: "content-bg",
                                },
                                [
                                  _c("img", {
                                    staticClass: "preview-img",
                                    attrs: {
                                      src: "data:image/jpeg;base64," + item,
                                      preview: "bill",
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("p", { staticStyle: { color: "#fff" } }, [
                            _vm._v("暂无图片"),
                          ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("物流单"),
                      ]),
                      _vm.detailData.imgContract
                        ? _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(_vm.imgLogistics, function (item, index) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: index < 3,
                                      expression: "index < 3",
                                    },
                                  ],
                                  key: index,
                                  staticClass: "content-bg",
                                },
                                [
                                  _c("img", {
                                    staticClass: "preview-img",
                                    attrs: {
                                      src: "data:image/jpeg;base64," + item,
                                      preview: "logistics",
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("p", { staticStyle: { color: "#fff" } }, [
                            _vm._v("暂无图片"),
                          ]),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("银行回单"),
                      ]),
                      _vm.detailData.imgContract
                        ? _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(_vm.imgReceipt, function (item, index) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: index < 3,
                                      expression: "index < 3",
                                    },
                                  ],
                                  key: index,
                                  staticClass: "content-bg",
                                },
                                [
                                  _c("img", {
                                    staticClass: "preview-img",
                                    attrs: {
                                      src: "data:image/jpeg;base64," + item,
                                      preview: "receipt",
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("p", { staticStyle: { color: "#fff" } }, [
                            _vm._v("暂无图片"),
                          ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("操作"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "content mouseStyle",
                          on: { click: _vm.showMore },
                        },
                        [_vm._v("点击查看设备信息")]
                      ),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("定位"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "content mouseStyle",
                          on: {
                            click: function ($event) {
                              return _vm.toPosition(_vm.detailData)
                            },
                          },
                        },
                        [_vm._v("点击跳转到项目位置")]
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("铝模板溯源模"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "content mouseStyle",
                          on: { click: _vm.showTraceability },
                        },
                        [_vm._v("点击查看铝模板溯源模")]
                      ),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _c("span", { staticClass: "form-title" }, [
                        _vm._v("物资交付"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "content mouseStyle",
                          on: { click: _vm.showDeliveries },
                        },
                        [_vm._v("点击查看物资交付信息")]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "contract-main-title" }, [
      _c("div", { staticClass: "line-left" }),
      _c("span", [_vm._v("合同信息")]),
      _c("div", { staticClass: "line-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }